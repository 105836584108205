var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "block",
    class: {
      selected: _vm.selected
    },
    style: Object.assign({}, _vm.position, _vm.size),
    attrs: {
      "id": "block",
      "draggable": ""
    },
    on: {
      "dragstart": _vm.dragstart,
      "drag": _vm.drag,
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickBlock.apply(null, arguments);
      }
    }
  }, [_vm._t("default", function () {
    return [_c('v-sheet', {
      staticClass: "fill-height px-2 pt-1 rounded",
      attrs: {
        "elevation": _vm.selected ? 4 : 2
      }
    }, [_vm.title ? _c('p', {
      staticClass: "text-body-2 font-weight-medium mb-0 text-truncate"
    }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.text ? _c('p', {
      staticClass: "text-caption my-0 text-truncate"
    }, [_vm._v(_vm._s(_vm.text))]) : _vm._e()])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }