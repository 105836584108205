<template>
    <div id='arrowArea' :style="{...containerSize}">
        <svg :viewBox="`0 0 ${width} ${height}`" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <marker id="start" markerWidth="8" markerHeight="8" refX="0" refY="4" orient="auto" markerUnits="strokeWidth" viewBox="0 0 20 20">
                <circle cx="4" cy="4" r="4"/>
            </marker>
            <marker id="end" markerWidth="8" markerHeight="8" refX="8" refY="4" orient="auto" markerUnits="strokeWidth" viewBox="0 0 20 20">
                <circle cx="4" cy="4" r="4"/>
            </marker>
        </defs>
            <g v-for="link in links" v-bind:key="link.ref" >
              <path
                :d="`M
                ${link.p1.x} ${link.p1.y} C
                ${link.p1.x} ${Math.max(link.p1.y + 50, (link.p1.y + link.p2.y)/2)},
                ${link.p2.x} ${Math.min(link.p2.y - 50, (link.p1.y + link.p2.y)/2)},
                ${link.p2.x} ${link.p2.y}
                `"
                :stroke="link.p2.y >= link.p1.y ? 'black' : 'red' "
                marker-end="url(#end)"
                marker-start="url(#start)"
                fill="transparent"
              />
            </g>
        </svg>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#arrowArea{ }
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'ChartArrows',
  components: {},
  props: {
      width: {type: Number, required: true },
      height: {type: Number, required: true },
      links: {type: Array, required: true },
  },
  data(){
    return {}
  },
  computed: {
      containerSize(){ return { width: `${this.width}px`, height: `${this.height}px` }; }
  },
  methods: {},
  created(){}
}
</script>
