<template>
  <div id='block' ref='block' :style='{...position, ...size}' class="" :class='{selected}'
    draggable @dragstart='dragstart' @drag='drag'
    @click.stop='clickBlock'
    >
    <slot>
      <v-sheet class='fill-height px-2 pt-1 rounded' :elevation="selected?4:2" >
        <p class='text-body-2 font-weight-medium mb-0 text-truncate' v-if="title">{{title}}</p>
        <p class='text-caption my-0 text-truncate' v-if="text">{{text}}</p>
      </v-sheet>
    </slot>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#block{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 280px;
  height: 70px;
  box-sizing: padding-box;

    border: 1px dashed transparent;
    &.selected{ border-color: black; }
}
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Block',
  components: { },
  props: {
    title: { type: String, required: false, default: '' },
    text: { type: String, required: false, default: '' },
    xpos: {type: Number, required: true },
    ypos: {type: Number, required: true },
    width: {type: Number, required: true },
    height: {type: Number, required: true },
    selected: {type: Boolean, required: false },
  },
  data(){
    return {
      block: document.createElement('div'),
      ready: false,
      dragFrom: { x: 0, y: 0, sx: 0, sy: 0, },
    }
  },
  computed: {
    position(){ return { left: `${this.xpos}px`, top: `${this.ypos}px` } },
    size(){ return { width: `${this.width}px`, height: `${this.height}px` } },
    parent() { return this.$el.parentElement.parentElement; }
  },
  methods: {
    dragstart(e){
      this.dragFrom = {
        x: this.xpos - e.clientX - this.parent.scrollLeft,
        y: this.ypos - e.clientY - this.parent.scrollTop,
      };
      e.dataTransfer.setDragImage(new Image(), 0, 0); // hide drag shadow
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text", "wow");
    },
    drag(e){
      if(!e.clientX && !e.clientY) return;
      //console.log(this.block.parentElement);
      let x = Math.min(Math.max(e.clientX + this.dragFrom.x + this.parent.scrollLeft, 0), this.block.parentElement.getBoundingClientRect().width - this.block.getBoundingClientRect().width);
      let y = Math.min(Math.max(e.clientY + this.dragFrom.y + this.parent.scrollTop, 0), this.block.parentElement.getBoundingClientRect().height - this.block.getBoundingClientRect().height);
      x = Math.round(x/25)*25;
      y = Math.round(y/25)*25;
      if(this.xpos == x && this.ypos == y) return;
      this.$emit('move', { x, y });
    },
    clickBlock(e){
      if(e.ctrlKey) this.$emit('link');
      else this.$emit('select');
    }
  },
  mounted(){
    this.block = this.$refs['block'];
    this.ready = true;
  }
}
</script>
