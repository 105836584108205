var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "overflow": "auto"
    }
  }, [_c('div', {
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      "id": "chart"
    },
    on: {
      "dragover": _vm.dragover,
      "click": function click($event) {
        _vm.selected = null;
      }
    }
  }, [_c('Arrows', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "links": _vm.links
    }
  }), _vm._l(_vm.blocks, function (block) {
    return [_c('Block', {
      key: block.id,
      attrs: {
        "blockId": block.id,
        "title": block.title,
        "text": block.text,
        "xpos": block.x,
        "ypos": block.y,
        "width": block.w,
        "height": block.h,
        "selected": _vm.selected == block.id
      },
      on: {
        "move": function move(pos) {
          block.x = pos.x;
          block.y = pos.y;
          _vm.selected = block.id;
          _vm.$emit("move", block);
        },
        "select": function select($event) {
          return _vm.selectBlock(block.id);
        },
        "link": function link($event) {
          return _vm.linkTo(block.id);
        }
      }
    }, [_vm._t("block", null, {
      "block": block,
      "selected": _vm.selected == block.id
    })], 2)];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }