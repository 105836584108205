<template>
  <div class='fill-height'>
    <Chart ref='chart'
      :blocks='blocks'
      @link='link'
      style='width:100%; height: 100%;'
    />
  </div>
</template>

<style lang="scss">
#blockArea{
  width: 100vw;
  height: 100vh;
  box-sizing: padding-box;
  position: absolute;
  top: 0px;
  left: 0px;
  //background-color: #CCC;
}
</style>


<script>
// @ is an alias to /src
//import Block from '@/components/Block'
//import ArrowLayer from '@/components/ArrowLayer'
import Chart from '@/components/chart/Chart';

export default {
  name: 'Home',
  components: {
    Chart,
  },
  data(){ return {
    chart: null,
    blocks: [
      { id: 'a', title: 'Powdercoat', text: 'Text A', x: 50, y: 50, w: 200, h: 50, links: [] },
      { id: 'b', title: 'Glass', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
      { id: 'c', title: 'Screens', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
      { id: 'd', title: 'Assembly', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
      { id: 'e', title: 'Installation', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
      { id: 'f', title: 'Check Measure', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
    ],
  }},
  methods: {
    link({from, to}){
      from = this.blocks.find(b => b.id == from);
      to = this.blocks.find(b => b.id == to);
      if(!(from && to)) return;
      if(from.links.includes(to.id)) return;
      from.links.push(to.id);
    },
  },
  mounted(){
    this.chart = this.$refs['chart'];
  }
}
</script>
