var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fill-height"
  }, [_c('Chart', {
    ref: "chart",
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "blocks": _vm.blocks
    },
    on: {
      "link": _vm.link
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }