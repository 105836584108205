var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: Object.assign({}, _vm.containerSize),
    attrs: {
      "id": "arrowArea"
    }
  }, [_c('svg', {
    attrs: {
      "viewBox": "0 0 ".concat(_vm.width, " ").concat(_vm.height),
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('defs', [_c('marker', {
    attrs: {
      "id": "start",
      "markerWidth": "8",
      "markerHeight": "8",
      "refX": "0",
      "refY": "4",
      "orient": "auto",
      "markerUnits": "strokeWidth",
      "viewBox": "0 0 20 20"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "4",
      "cy": "4",
      "r": "4"
    }
  })]), _c('marker', {
    attrs: {
      "id": "end",
      "markerWidth": "8",
      "markerHeight": "8",
      "refX": "8",
      "refY": "4",
      "orient": "auto",
      "markerUnits": "strokeWidth",
      "viewBox": "0 0 20 20"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "4",
      "cy": "4",
      "r": "4"
    }
  })])]), _vm._l(_vm.links, function (link) {
    return _c('g', {
      key: link.ref
    }, [_c('path', {
      attrs: {
        "d": "M\n            ".concat(link.p1.x, " ").concat(link.p1.y, " C\n            ").concat(link.p1.x, " ").concat(Math.max(link.p1.y + 50, (link.p1.y + link.p2.y) / 2), ",\n            ").concat(link.p2.x, " ").concat(Math.min(link.p2.y - 50, (link.p1.y + link.p2.y) / 2), ",\n            ").concat(link.p2.x, " ").concat(link.p2.y, "\n            "),
        "stroke": link.p2.y >= link.p1.y ? 'black' : 'red',
        "marker-end": "url(#end)",
        "marker-start": "url(#start)",
        "fill": "transparent"
      }
    })]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }