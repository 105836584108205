<template>
  <div style='width: 100%; height: 100%; overflow: auto;'>
    <div id='chart'
      @dragover='dragover'
      @click='selected=null'
      :style='{width:`${width}px`, height:`${height}px`}'
    >
        <Arrows :width='width' :height='height' :links='links' />
      <template v-for='block in blocks'>
        <Block v-bind:key='block.id'
          :blockId='block.id'
          :title='block.title'
          :text='block.text'
          :xpos='block.x'
          :ypos='block.y'
          :width='block.w'
          :height='block.h'
          :selected='selected==block.id'
          @move='(pos) => { block.x = pos.x; block.y = pos.y; selected = block.id; $emit("move", block); }'
          @select='selectBlock(block.id)'
          @link='linkTo(block.id)'
        >
          <slot name="block" :block="block" :selected="(selected==block.id)"></slot>
        </Block>
      </template>
    </div>
  </div>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#chart{
  min-width: 100%;
  min-height: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
import Block from './Block';
import Arrows from './Arrows';
export default {
  name: 'Chart',
  components: { Block, Arrows },
  props: {
    blocks: { type: Array, required: true },
  },
  data(){
    return {
      selected: null,
    }
  },
  computed: {
    width(){ return this.blocks.reduce((max, b) => Math.max(b.x+300, max), 0); },
    height(){ return this.blocks.reduce((max, b) => Math.max(b.y+100, max), 0); },
    links(){
        let links = [];
        this.blocks.forEach(b1 => {
            b1.links.forEach(link_to => {
                let b2 = this.blocks.find(b_to => b_to.id == link_to);
                if(b1 && b2) links.push({
                    p2: { x: b1.x + b1.w/2, y: b1.y  },
                    p1: { x: b2.x + b2.w/2, y: b2.y + b2.h },
                });
            });
        });
        return links;
    }
  },
  watch:{
    selected(xid){ this.$emit('select', xid); }
  },
  methods: {
    dragover(e){
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    },
    selectBlock(blockId){
      this.selected = this.selected != blockId ? blockId : null;
    },
    linkTo(blockId){
      if(this.selected && this.selected != blockId)
        this.$emit("link", { to: this.selected, from: blockId });
    },
  },
  created(){}
}
</script>
